export default [
  {
    title: 'Categories',
    icon: 'CreditCardIcon',
    children: [
      {
        title: 'Servicees Categories',
        route: 'services-categories',
      },
      {
        title: 'Products Categories',
        route: 'products-categories',
      },
      {
        title: 'Blog Categories',
        route: 'blog-categories',
      },
    ],
  },
  {
    title: 'Certifications',
    icon: 'AwardIcon',
    route: 'certifications',
  },
]
