export default [
  {
    title: 'Home',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Products',
    route: 'products',
    icon: 'ShoppingCartIcon',
  },
  {
    title: 'Services',
    route: 'services',
    icon: 'GridIcon',
  },
  {
    title: 'Events',
    route: 'events',
    icon: 'CoffeeIcon',
  },
  {
    title: 'About',
    route: 'about',
    icon: 'AlertCircleIcon',
  },
  {
    title: 'Blog',
    route: 'blog',
    icon: 'BookIcon',
  },
  {
    title: 'Slider',
    route: 'slider',
    icon: 'SlidersIcon',
  },
  {
    title: 'Contact',
    route: 'contact-us',
    icon: 'PhoneIcon',
  },
  {
    title: 'Footer',
    route: 'footer',
    icon: 'DatabaseIcon',
  },
  {
    title: 'Support Numbers',
    route: 'support',
    icon: 'HeadphonesIcon',
  },
  {
    title: 'Headers',
    route: 'headers',
    icon: 'AlignJustifyIcon',
  },
  {
    title: 'Languages',
    route: 'languages',
    icon: 'TwitchIcon',
  },
]
